import React from "react";
import { navigate } from "gatsby";

import { Grid, Cell, Button } from "../../components/ui";
import {
  ContentObject,
  ContentItem,
  FormattedContentItem,
} from "../../components/content";

import { useStyletron } from "baseui";
import { DisplayLarge, ParagraphLarge } from "baseui/typography";

import dotGridBox from "../../assets/images/dot-grid-box.svg";
import dotGridWhiteBox from "../../assets/images/dot-grid-white-box.svg";
import { useTheme } from "../../contexts/theme";

const Estimate = () => {
  const [css, theme] = useStyletron();
  const { useDarkTheme } = useTheme();

  return (
    <article
      data-csweb="services-estimate"
      className={css({
        paddingTop: theme.sizing.scale800,
        paddingBottom: theme.sizing.scale2400,
        [theme.breakpoints.mediaQueries.large]: {
          paddingTop: theme.sizing.scale100,
          paddingBottom: theme.sizing.scale100,
        },
      })}
    >
      <Grid>
        <Cell skip={[2, 4, 6]} span={[2, 4, 6]}>
          <div
            className={css({
              position: "relative",
            })}
          >
            <div
              className={css({
                position: "absolute",
                zIndex: "0",
                marginTop: "10%",
                width: "100%",
                height: "40vh",
                opacity: "0.3",
                backgroundColor: theme.colors.backgroundLightAccent,
              })}
            ></div>
          </div>
        </Cell>
        <Cell skip={[0, 0, 1]} span={[4, 8, 10]}>
          <div
            className={css({
              paddingTop: theme.sizing.scale3200,
              paddingBottom: theme.sizing.scale3200,
              position: "relative",
              zIndex: "4",
              [theme.breakpoints.mediaQueries.medium]: {
                paddingTop: theme.sizing.scale2400,
                paddingBottom: theme.sizing.scale2400,
              },
              [theme.breakpoints.mediaQueries.small]: {
                paddingTop: theme.sizing.scale1200,
                paddingBottom: theme.sizing.scale1200,
              },
            })}
          >
            <DisplayLarge
              $style={{
                textAlign: "center",
                fontWeight: "400",
                [theme.breakpoints.mediaQueries.medium]: {
                  textAlign: "center",
                  ...theme.typography.DisplayMedium,
                  fontWeight: "bold",
                },
                [theme.breakpoints.mediaQueries.small]: {
                  ...theme.typography.DisplayXSmall,
                },
              }}
            >
              <FormattedContentItem scope="Services" accessor="estimate.lead" />
            </DisplayLarge>
            <div
              className={css({
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: theme.sizing.scale1400,
                marginBottom: theme.sizing.scale1400,
                [theme.breakpoints.mediaQueries.small]: {
                  flexDirection: "column",
                },
              })}
            >
              <Button
                kind={ContentObject.Services.estimate.buttons[0].kind}
                label={ContentObject.Services.estimate.buttons[0].label}
                onClick={() =>
                  navigate(ContentObject.Services.estimate.buttons[0].to)
                }
              >
                <ContentItem
                  scope="Services"
                  accessor={`estimate.buttons.0.title`}
                />
              </Button>
              <div
                className={css({
                  fontSize: "15px",
                  fontWeight: "600",
                  lineHeight: "46px",
                  marginLeft: theme.sizing.scale1000,
                  marginRight: theme.sizing.scale1000,
                })}
              >
                or
              </div>
              <Button
                kind={ContentObject.Services.estimate.buttons[1].kind}
                label={ContentObject.Services.estimate.buttons[1].label}
                onClick={() =>
                  navigate(ContentObject.Services.estimate.buttons[1].to)
                }
              >
                <ContentItem
                  scope="Services"
                  accessor={`estimate.buttons.1.title`}
                />
              </Button>
            </div>
            <ParagraphLarge
              $style={{
                marginTop: theme.sizing.scale1400,
                justifyContent: "center",
                textAlign: "center",
                fontWeight: "700",
              }}
            >
              <FormattedContentItem
                scope="Services"
                accessor="estimate.subtext"
              />
            </ParagraphLarge>
          </div>
        </Cell>
        <Cell skip={2} span={4}>
          <div
            className={css({
              position: "relative",
              width: "100%",
              height: "100%",
              marginTop: "-420px",
            })}
          >
            <img
              alt="Dot grid background"
              className={css({
                position: "absolute",
                top: "0%",
                left: "0%",
                zIndex: "0",
                pointerEvents: "none",
              })}
              src={useDarkTheme ? dotGridWhiteBox : dotGridBox}
            />
          </div>
        </Cell>
      </Grid>
    </article>
  );
};

export default Estimate;
