import React from "react";

import { Grid, Cell } from "../../components/ui";
import { ContentObject, ContentItem } from "../../components/content";

import { useStyletron } from "baseui";
import { HeadingLarge, LabelXSmall } from "baseui/typography";

import Logos from "../../assets/images/technologies";

import dotGridBox from "../../assets/images/dot-grid-box.svg";
import dotGridWhiteBox from "../../assets/images/dot-grid-white-box.svg";
import { useTheme } from "../../contexts/theme";

const Technologies = () => {
  const [css, theme] = useStyletron();
  const { useDarkTheme } = useTheme();

  return (
    <article data-csweb="services-technologies">
      <Grid>
        <Cell skip={[1, 1, 1]} span={[3, 5, 5]}>
          <div
            className={css({
              position: "relative",
            })}
          >
            <img
              alt="Dot grid background"
              className={css({
                position: "absolute",
                marginTop: "-20%",
                left: "0%",
                zIndex: "0",
                pointerEvents: "none",
              })}
              src={useDarkTheme ? dotGridWhiteBox : dotGridBox}
            />
            <div
              className={css({
                position: "absolute",
                zIndex: "0",
                marginTop: "9vh",
                width: "100%",
                height: "40vh",
                opacity: "0.3",
                backgroundColor: theme.colors.backgroundLightAccent,
              })}
            ></div>
          </div>
        </Cell>
        <Cell span={5}>
          <div
            className={css({
              position: "relative",
            })}
          >
            <img
              alt="Dot grid background"
              className={css({
                position: "absolute",
                marginTop: "20%",
                left: "0%",
                zIndex: "0",
                pointerEvents: "none",
              })}
              src={useDarkTheme ? dotGridWhiteBox : dotGridBox}
            />
          </div>
        </Cell>
        <Cell skip={[0, 0, 1]} span={[4, 8, 10]}>
          <div
            className={css({
              paddingTop: theme.sizing.scale3200,
              paddingBottom: theme.sizing.scale1600,
              position: "relative",
              zIndex: "2",
              [theme.breakpoints.mediaQueries.medium]: {
                paddingBottom: theme.sizing.scale0,
                paddingTop: theme.sizing.scale1200,
              },
            })}
          >
            <HeadingLarge
              $style={{
                textAlign: "center",
                [theme.breakpoints.mediaQueries.medium]: {
                  paddingBottom: theme.sizing.scale1200,
                },
              }}
              marginBottom="scale100"
            >
              <ContentItem scope="Services" accessor="technologies.title" />
            </HeadingLarge>
            <div
              className={css({
                paddingTop: theme.sizing.scale1200,
                paddingBottom: theme.sizing.scale1000,
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                position: "relative",
                [theme.breakpoints.mediaQueries.medium]: {
                  paddingTop: theme.sizing.scale200,
                  paddingBottom: theme.sizing.scale200,
                  flexWrap: "wrap",
                  justifyContent: "center",
                },
              })}
            >
              {ContentObject.Services.technologies.items.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={css({
                      paddingTop: theme.sizing.scale900,
                      paddingBottom: theme.sizing.scale1200,
                      textAlign: "center",
                      height: "130px",
                      position: "relative",
                      display: "flex",
                      minWidth: `40px`,
                      alignItems: "center",
                      justifyContent: "center",
                      boxSizing: "border-box",
                      transitionProperty: "all",
                      transitionDuration: "200ms",
                      transitionTimingFunction: "ease-in-out",
                      ":hover": {
                        paddingTop: theme.sizing.scale500,
                      },
                      ":hover div": {
                        bottom: "25px",
                        opacity: "1",
                      },
                      [theme.breakpoints.mediaQueries.medium]: {
                        marginLeft: theme.sizing.scale800,
                        marginRight: theme.sizing.scale800,
                        marginTop: theme.sizing.scale0,
                        marginBottom: theme.sizing.scale0,
                        paddingBottom: theme.sizing.scale900,
                        height: "auto",
                        ":hover": {
                          paddingTop: theme.sizing.scale200,
                        },
                        ":hover div": {
                          bottom: "5px",
                        },
                      },
                      [theme.breakpoints.mediaQueries.small]: {
                        paddingTop: theme.sizing.scale500,
                        minWidth: "7ch",
                      },
                    })}
                  >
                    <img
                      src={Logos[item].icon}
                      alt={Logos[item].label}
                      className={css({
                        maxWidth: "70px",
                        maxHeight: "45px",
                        filter: useDarkTheme
                          ? "brightness(0) invert(1)"
                          : "invert(1)",
                      })}
                    />
                    <LabelXSmall
                      $style={{
                        position: "absolute",
                        textTransform: "uppercase",
                        letterSpacing: "2.5px",
                        paddingLeft: "2.5px",
                        fontSize: "10px",
                        bottom: "-20px",
                        transitionProperty: "all",
                        transitionDuration: "200ms",
                        transitionTimingFunction: "ease-in-out",
                        opacity: "0",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {Logos[item].label}
                    </LabelXSmall>
                  </div>
                );
              })}
            </div>
          </div>
        </Cell>
      </Grid>
    </article>
  );
};

export default Technologies;
