import React from "react";

import { Grid, Cell } from "../../components/ui";
import { FormattedContentItem } from "../../components/content";

import { useStyletron } from "baseui";
import { HeadingXXLarge } from "baseui/typography";

const Heading = () => {
  const [css, theme] = useStyletron();

  return (
    <article
      data-csweb="services-heading"
      className={css({
        backgroundColor: theme.colors.backgroundSecondary,
        paddingTop: theme.sizing.scale4800,
        paddingBottom: theme.sizing.scale3200,
        [theme.breakpoints.mediaQueries.medium]: {
          paddingTop: theme.sizing.scale2400,
          paddingBottom: theme.sizing.scale1600,
        },
      })}
    >
      <Grid>
        <Cell span={[4, 8, 12]}>
          <HeadingXXLarge $style={{ textAlign: "center" }}>
            <FormattedContentItem scope="Services" accessor="heading" />
          </HeadingXXLarge>
        </Cell>
      </Grid>
    </article>
  );
};

export default Heading;
