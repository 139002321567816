import React, { useEffect, useState } from "react";

import { Grid, Cell } from "../../components/ui";
import { FormattedContentItem, ContentObject } from "../../components/content";

import { useStyletron } from "baseui";
import { HeadingMedium, LabelLarge } from "baseui/typography";

import shine from "../../assets/images/shine.svg";

import VisibilitySensor from "react-visibility-sensor";
import { Spring } from "react-spring/renderprops";

import { useTheme } from "../../contexts/theme";

const Cycle = () => {
  const [css, theme] = useStyletron();
  const [isSticked, setIsSticked] = useState(false);
  const [isAfter, setIsAfter] = useState(false);
  const { useDarkTheme } = useTheme();

  let Motus;

  if (typeof window !== "undefined" && window) Motus = require("motus");

  const onScroll = () => {
    if (
      window.scrollY >
      document.querySelector("[data-csweb=cycle-circular]").offsetTop -
        0.2 * window.innerHeight
    ) {
      if (
        window.scrollY + window.innerHeight <
        document.querySelector("[data-csweb=cycle-circular]").offsetTop +
          document
            .querySelector("[data-csweb=cycle-circular]")
            .getBoundingClientRect().height
      ) {
        setIsSticked(true);
        setIsAfter(false);
      } else {
        setIsAfter(true);
        setIsSticked(false);
      }
    } else {
      setIsSticked(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    if (typeof window !== "undefined" && window) {
      if (Motus && Motus.default && Motus.default.Animation) {
        Motus.default.clearAnimations();
        Motus.default.addAnimation(
          new Motus.default.Animation({
            startPoint: document.querySelector("[data-csweb=services-cycle]")
              .offsetTop,
            endPoint:
              document.querySelector("[data-csweb=cycle-circular]").offsetTop +
              1400,
            $el: document.querySelector("[data-csweb=cycle-circular-wrapper]"),
            keyframes: [
              {
                rotate: [`0deg`],
              },
              { rotate: [`180deg`] },
            ],
          })
        );

        Motus.default.addAnimation(
          new Motus.default.Animation({
            startPoint:
              document.querySelector("[data-csweb=services-cycle-wrapper-1]")
                .offsetTop - 300,
            endPoint:
              document.querySelector("[data-csweb=services-cycle-wrapper-1]")
                .offsetTop - 20,
            $el: document.querySelector("[data-csweb=services-cycle-dot-1]"),
            keyframes: [
              {
                top: 0,
              },
              {
                top:
                  document.querySelector(
                    "[data-csweb=services-cycle-wrapper-1]"
                  ).scrollHeight - 20,
              },
            ],
          })
        );

        Motus.default.addAnimation(
          new Motus.default.Animation({
            startPoint:
              document.querySelector("[data-csweb=services-cycle-wrapper-2]")
                .offsetTop - 400,
            endPoint:
              document.querySelector("[data-csweb=services-cycle-wrapper-2]")
                .offsetTop - 200,
            $el: document.querySelector("[data-csweb=services-cycle-dot-2]"),
            keyframes: [
              {
                top: 0,
              },
              {
                top:
                  document.querySelector(
                    "[data-csweb=services-cycle-wrapper-2]"
                  ).scrollHeight - 20,
              },
            ],
          })
        );

        Motus.default.addAnimation(
          new Motus.default.Animation({
            startPoint:
              document.querySelector("[data-csweb=services-cycle-wrapper-1]")
                .offsetTop - 400,
            endPoint: document.querySelector(
              "[data-csweb=services-cycle-wrapper-1]"
            ).offsetTop,
            $el: document.querySelector("[data-csweb=services-cycle-label-1]"),
            keyframes: [
              {
                opacity: 1,
              },
              {
                opacity: 0,
              },
            ],
          })
        );

        Motus.default.addAnimation(
          new Motus.default.Animation({
            startPoint:
              document.querySelector("[data-csweb=services-cycle-wrapper-2]")
                .offsetTop - 400,
            endPoint: document.querySelector(
              "[data-csweb=services-cycle-wrapper-2]"
            ).offsetTop,
            $el: document.querySelector("[data-csweb=services-cycle-label-2]"),
            keyframes: [
              {
                opacity: 1,
              },
              {
                opacity: 0,
              },
            ],
          })
        );

        document
          .querySelectorAll("[data-csweb=cycle-circular-text]")
          .forEach(value => {
            Motus.default.addAnimation(
              new Motus.default.Animation({
                startPoint: document.querySelector(
                  "[data-csweb=services-cycle]"
                ).offsetTop,
                endPoint:
                  document.querySelector("[data-csweb=cycle-circular]")
                    .offsetTop + 1400,
                $el: value,
                keyframes: [{ rotate: [`0deg`] }, { rotate: [`-180deg`] }],
              })
            );
          });

        document
          .querySelectorAll("[data-csweb=cycle-circular-element]")
          .forEach(value => {
            console.log(value.attributes);
            Motus.default.addAnimation(
              new Motus.default.Animation({
                startPoint: document.querySelector(
                  "[data-csweb=services-cycle]"
                ).offsetTop,
                endPoint:
                  document.querySelector("[data-csweb=cycle-circular]")
                    .offsetTop + 1400,
                $el: value,
                keyframes: {
                  ...(value.attributes["data-csweb-index"].value === "0" && {
                    0: { grayscale: [`0`] },
                    12: { grayscale: [`0`] },
                    25: { grayscale: [`0`] },
                    100: { grayscale: [`0`] },
                  }),
                  ...(value.attributes["data-csweb-index"].value === "2" && {
                    0: { grayscale: [`0`] },
                    25: { grayscale: [`0`] },
                    37: { grayscale: [`0`] },
                    50: { grayscale: [`0`] },
                    100: { grayscale: [`0`] },
                  }),
                  ...(value.attributes["data-csweb-index"].value === "3" && {
                    0: { grayscale: [`0`] },
                    50: { grayscale: [`0`] },
                    62: { grayscale: [`0`] },
                    75: { grayscale: [`0`] },
                    100: { grayscale: [`0`] },
                  }),
                  ...(value.attributes["data-csweb-index"].value === "1" && {
                    0: { grayscale: [`0`] },
                    75: { grayscale: [`0`] },
                    87: { grayscale: [`0`] },
                    100: { grayscale: [`0`] },
                  }),
                },
              })
            );
          });
      }
    }
  }, []);

  useEffect(
    () => () => {
      Motus.default.clearAnimations();

      window.removeEventListener("scroll", onScroll);
    },
    []
  );

  return (
    <article data-csweb="services-cycle">
      <Grid>
        <Cell skip={[0, 0, 1]} span={[4, 8, 10]}>
          <div
            className={css({
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            })}
          >
            <VisibilitySensor
              partialVisibility
              offset={{
                top:
                  typeof window !== "undefined" && window
                    ? window.innerHeight / 3 + 60
                    : 0,
              }}
            >
              {({ isVisible }) => (
                <Spring
                  from={{ scale: 0.5, color: "#ffffff" }}
                  to={{
                    scale: 1,
                    color: isVisible ? "#3c00dc" : "#ffffff",
                  }}
                >
                  {({ scale, color }) => (
                    <div
                      className={css({
                        position: "relative",
                        width: "95px",
                        height: "95px",
                        transform: `translate(0%, -50%) scale(${scale})`,
                      })}
                    >
                      <div
                        className={css({
                          position: "absolute",
                          width: "15px",
                          height: "15px",
                          background: color,
                          zIndex: "3",
                          left: "50%",
                          top: "50%",
                          borderTopLeftRadius: "50%",
                          borderTopRightRadius: "50%",
                          borderBottomRightRadius: "50%",
                          borderBottomLeftRadius: "50%",
                          transform: "translate(-50%, -50%)",
                          overflow: "hidden",
                        })}
                      ></div>
                      <div
                        className={css({
                          position: "absolute",
                          width: "80px",
                          height: "80px",
                          borderTopLeftRadius: "50%",
                          borderTopRightRadius: "50%",
                          borderBottomRightRadius: "50%",
                          borderBottomLeftRadius: "50%",
                          background: theme.colors.backgroundLightAccent,
                          zIndex: "2",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        })}
                      ></div>
                      <div
                        className={css({
                          position: "absolute",
                          width: "95px",
                          height: "95px",
                          zIndex: "1",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                          overflow: "hidden",
                        })}
                      >
                        <div
                          className={css({
                            position: "absolute",
                            width: "95px",
                            height: "95px",
                            zIndex: "1",
                            borderTopLeftRadius: "50%",
                            borderTopRightRadius: "50%",
                            borderBottomRightRadius: "50%",
                            borderBottomLeftRadius: "50%",
                            clipPath: `polygon(0% 0%, 50% 0%, 50% 50%, 0% 50%)`,
                            animationDuration: "1000ms",
                            animationFillMode: "both",
                            animationTimingFunction: "ease-in-out",
                            animationIterationCount: "0",
                            animationName: {
                              from: {
                                transform: "rotate(0deg)",
                              },
                              to: {
                                transform: "rotate(-360deg)",
                              },
                            },
                            "::before": {
                              content: "''",
                              position: "absolute",
                              left: "0px",
                              top: "0px",
                              right: "0px",
                              bottom: "0px",
                              borderTopLeftRadius: "50%",
                              borderTopRightRadius: "50%",
                              borderBottomRightRadius: "50%",
                              borderBottomLeftRadius: "50%",
                              background: theme.colors.backgroundPrimary,
                              animationDuration: "1000ms",
                              animationFillMode: "both",
                              animationTimingFunction: "ease-in-out",
                              animationIterationCount: "0",
                              animationName: {
                                from: {
                                  transform: "rotate(0deg)",
                                },
                                to: {
                                  transform: "rotate(360deg)",
                                },
                              },
                            },
                          })}
                        ></div>
                      </div>
                    </div>
                  )}
                </Spring>
              )}
            </VisibilitySensor>
            <Spring from={{ top: -20, opacity: 0 }} to={{ top: 0, opacity: 1 }}>
              {({ top, ...style }) => (
                <HeadingMedium
                  marginTop={`-${theme.sizing.scale800}`}
                  $style={{
                    textAlign: "center",
                    transform: `translateY(${top}px)`,
                    ...style,
                  }}
                >
                  <FormattedContentItem
                    scope="Services"
                    accessor="cycle.vertical.0"
                  />
                </HeadingMedium>
              )}
            </Spring>
            <div
              className={css({
                position: "relative",
                height: "100%",
                paddingTop: theme.sizing.scale1400,
                paddingBottom: theme.sizing.scale1600,
              })}
              data-csweb="services-cycle-wrapper-1"
            >
              <Spring
                from={{ height: 0, opacity: 0 }}
                to={{ height: 100, opacity: 1 }}
              >
                {({ height, ...style }) => (
                  <div
                    className={css({
                      position: "absolute",
                      height: `${height}%`,
                      width: "2px",
                      margin: "auto",
                      display: "block",
                      backgroundImage: `linear-gradient(${theme.colors.backgroundLightAccent} 50%, rgba(255,255,255,0) 0%)`,
                      backgroundPosition: "right",
                      backgroundSize: "2px 10px",
                      backgroundRepeat: "repeat-y",
                      transform: "translate(-50%, 0%)",
                      left: "50%",
                      top: "0%",
                      zIndex: "2",
                      ...style,
                    })}
                  ></div>
                )}
              </Spring>

              <VisibilitySensor
                partialVisibility
                offset={{
                  bottom:
                    typeof window !== "undefined" && window
                      ? window.innerHeight / 2 - 15
                      : 0,
                }}
              >
                {({ isVisible }) => (
                  <Spring
                    to={{
                      scale: isVisible ? 1 : 0.5,
                      opacity: isVisible ? 1 : 0,
                    }}
                  >
                    {({ scale, ...style }) => (
                      <div
                        data-csweb="services-cycle-dot-1"
                        className={css({
                          position: "absolute",
                          width: "15px",
                          height: "15px",
                          background: theme.colors.primary,
                          zIndex: "3",
                          borderTopLeftRadius: "50%",
                          borderTopRightRadius: "50%",
                          borderBottomRightRadius: "50%",
                          borderBottomLeftRadius: "50%",
                          left: "50%",
                          transform: `translate(-50%, 0%) scale(${scale})`,
                          overflow: "hidden",
                          ...style,
                        })}
                      ></div>
                    )}
                  </Spring>
                )}
              </VisibilitySensor>
              <Spring
                from={{ top: -20, opacity: 0 }}
                to={{ top: 0, opacity: 1 }}
              >
                {({ top, ...style }) => (
                  <LabelLarge
                    data-csweb="services-cycle-label-1"
                    marginTop="scale800"
                    $style={{
                      fontWeight: "700",
                      zIndex: "5",
                      position: "relative",
                      transform: `translateY(${top}px)`,
                      ...style,
                    }}
                  >
                    <FormattedContentItem
                      scope="Services"
                      accessor="cycle.vertical.1"
                    />
                  </LabelLarge>
                )}
              </Spring>
            </div>
            <div
              data-csweb="cycle-circular"
              className={css({
                position: "relative",
                height: "2200px",
                paddingTop: theme.sizing.scale1200,
                paddingBottom: theme.sizing.scale1200,
                marginTop: theme.sizing.scale1200,
                marginBottom: theme.sizing.scale1200,
              })}
            >
              <div
                className={css({
                  position: isSticked ? "fixed" : "absolute",
                  top: isAfter ? "unset" : "50px",
                  bottom: isAfter ? "50px" : "unset",
                  width: "500px",
                  height: "500px",
                  transform: "translateX(-50%)",
                  ...(isSticked && {
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -40%)",
                  }),
                  [theme.breakpoints.mediaQueries.medium]: {
                    width: "66vw",
                    height: "66vw",
                  },
                })}
              >
                <div
                  className={css({
                    background:
                      "linear-gradient(90deg, rgba(60,0,220,1) 0%, rgba(0,220,230,1) 100%)",
                    paddingTop: theme.sizing.scale200,
                    paddingBottom: theme.sizing.scale200,
                    paddingLeft: theme.sizing.scale500,
                    paddingRight: theme.sizing.scale500,
                    fontSize: "20px",
                    fontWeight: "700",
                    color: theme.colors.white,
                    left: "50%",
                    top: "50%",
                    position: "absolute",
                    width: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    transform: "translate(-50%, -50%)",
                    [theme.breakpoints.mediaQueries.small]: {
                      textAlign: "center",
                      zIndex: "8",
                      backgroundColor: "unset",
                      color: useDarkTheme
                        ? theme.colors.white
                        : theme.colors.black,
                    },
                  })}
                >
                  <FormattedContentItem
                    scope="Services"
                    accessor="cycle.title"
                  />
                  <img
                    alt="Shine"
                    src={shine}
                    height="40px"
                    className={css({
                      [theme.breakpoints.mediaQueries.small]: {
                        display: "none",
                      },
                    })}
                  />
                </div>
                <div
                  data-csweb="cycle-circular-wrapper"
                  className={css({
                    width: "100%",
                    height: "100%",
                    borderTopLeftRadius: "50%",
                    borderTopRightRadius: "50%",
                    borderBottomRightRadius: "50%",
                    borderBottomLeftRadius: "50%",
                    borderTopWidth: "1px",
                    borderRightWidth: "1px",
                    borderBottomWidth: "1px",
                    borderLeftWidth: "1px",
                    borderTopStyle: "solid",
                    borderRightStyle: "solid",
                    borderBottomStyle: "solid",
                    borderLeftStyle: "solid",
                    borderTopColor: theme.colors.backgroundLightAccent,
                    borderRightColor: theme.colors.backgroundLightAccent,
                    borderBottomColor: theme.colors.backgroundLightAccent,
                    borderLeftColor: theme.colors.backgroundLightAccent,
                  })}
                >
                  {ContentObject.Services.cycle.circular.map((item, index) => (
                    <div
                      data-csweb={`cycle-circular-element`}
                      data-csweb-index={index}
                      key={index}
                      className={css({
                        position: "absolute",
                        width: "120px",
                        height: "120px",
                        ...(index === 0
                          ? {
                              left: "50%",
                              top: "0%",
                              transform: "translate(-50%, -50%)",
                            }
                          : index === 1
                          ? {
                              right: "0%",
                              top: "50%",
                              transform: "translate(50%, -50%)",
                            }
                          : index === 2
                          ? {
                              left: "0%",
                              bottom: "50%",
                              transform: "translate(-50%, 50%)",
                            }
                          : {
                              right: "50%",
                              bottom: "0%",
                              transform: "translate(50%, 50%)",
                            }),
                        // ...(index === 0
                        //   ? {
                        //       animationIterationCount: "0",
                        //       animationDuration: "10000ms",
                        //       animationTimingFunction: "linear",
                        //       animationFillMode: "both",
                        //       animationName: {
                        //         "0%": {
                        //           width: "120px",
                        //           height: "120px",
                        //           fontSize: "14px",
                        //         },
                        //         "6.25%": {
                        //           width: "190px",
                        //           height: "190px",
                        //           fontSize: "22.5px",
                        //         },
                        //         "18.75%": {
                        //           width: "190px",
                        //           height: "190px",
                        //           fontSize: "22.5px",
                        //         },
                        //         "25%": {
                        //           width: "120px",
                        //           height: "120px",
                        //           fontSize: "14px",
                        //         },
                        //         "100%": {
                        //           width: "120px",
                        //           height: "120px",
                        //           fontSize: "14px",
                        //         },
                        //       },
                        //     }
                        //   : index === 1
                        //   ? {
                        //       animationIterationCount: "0",
                        //       animationDuration: "10000ms",
                        //       animationTimingFunction: "linear",
                        //       animationFillMode: "both",
                        //       animationName: {
                        //         "0%": {
                        //           width: "120px",
                        //           height: "120px",
                        //           fontSize: "14px",
                        //         },
                        //         "75%": {
                        //           width: "120px",
                        //           height: "120px",
                        //           fontSize: "14px",
                        //         },
                        //         "81.25%": {
                        //           width: "190px",
                        //           height: "190px",
                        //           fontSize: "22.5px",
                        //         },
                        //         "93.75%": {
                        //           width: "190px",
                        //           height: "190px",
                        //           fontSize: "22.5px",
                        //         },
                        //         "100%": {
                        //           width: "120px",
                        //           height: "120px",
                        //           fontSize: "14px",
                        //         },
                        //       },
                        //     }
                        //   : index === 2
                        //   ? {
                        //       animationIterationCount: "0",
                        //       animationDuration: "10000ms",
                        //       animationTimingFunction: "linear",
                        //       animationFillMode: "both",
                        //       animationName: {
                        //         "0%": {
                        //           width: "120px",
                        //           height: "120px",
                        //           fontSize: "14px",
                        //         },
                        //         "25%": {
                        //           width: "120px",
                        //           height: "120px",
                        //           fontSize: "14px",
                        //         },
                        //         "31.25%": {
                        //           width: "190px",
                        //           height: "190px",
                        //           fontSize: "22.5px",
                        //         },
                        //         "43.75%": {
                        //           width: "190px",
                        //           height: "190px",
                        //           fontSize: "22.5px",
                        //         },
                        //         "50%": {
                        //           width: "120px",
                        //           height: "120px",
                        //           fontSize: "14px",
                        //         },
                        //         "100%": {
                        //           width: "120px",
                        //           height: "120px",
                        //           fontSize: "14px",
                        //         },
                        //       },
                        //     }
                        //   : {
                        //       animationIterationCount: "0",
                        //       animationDuration: "10000ms",
                        //       animationTimingFunction: "linear",
                        //       animationFillMode: "both",
                        //       animationName: {
                        //         "0%": {
                        //           width: "120px",
                        //           height: "120px",
                        //           fontSize: "14px",
                        //         },
                        //         "50%": {
                        //           width: "120px",
                        //           height: "120px",
                        //           fontSize: "14px",
                        //         },
                        //         "56.25%": {
                        //           width: "190px",
                        //           height: "190px",
                        //           fontSize: "22.5px",
                        //         },
                        //         "68.75%": {
                        //           width: "190px",
                        //           height: "190px",
                        //           fontSize: "22.5px",
                        //         },
                        //         "75%": {
                        //           width: "120px",
                        //           height: "120px",
                        //           fontSize: "14px",
                        //         },
                        //         "100%": {
                        //           width: "120px",
                        //           height: "120px",
                        //           fontSize: "14px",
                        //         },
                        //       },
                        //     }),
                        [theme.breakpoints.mediaQueries.medium]: {
                          width: "100px",
                          height: "100px",
                        },
                      })}
                    >
                      <div
                        className={css({
                          position: "absolute",
                          width: "140%",
                          height: "140%",
                          maxWidth: "180px",
                          maxHeight: "180px",
                          borderTopLeftRadius: "50%",
                          borderTopRightRadius: "50%",
                          borderBottomRightRadius: "50%",
                          borderBottomLeftRadius: "50%",
                          backgroundColor: theme.colors.backgroundPrimary,
                          zIndex: "1",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                        })}
                      ></div>
                      <div
                        className={css({
                          position: "absolute",
                          width: "85%",
                          height: "85%",
                          borderTopLeftRadius: "50%",
                          borderTopRightRadius: "50%",
                          borderBottomRightRadius: "50%",
                          borderBottomLeftRadius: "50%",
                          borderTopWidth: "1px",
                          borderRightWidth: "1px",
                          borderBottomWidth: "1px",
                          borderLeftWidth: "1px",
                          borderTopStyle: "solid",
                          borderRightStyle: "solid",
                          borderBottomStyle: "solid",
                          borderLeftStyle: "solid",
                          borderTopColor: theme.colors.backgroundLightAccent,
                          borderRightColor: theme.colors.backgroundLightAccent,
                          borderBottomColor: theme.colors.backgroundLightAccent,
                          borderLeftColor: theme.colors.backgroundLightAccent,
                          backgroundColor: theme.colors.backgroundPrimary,
                          zIndex: "2",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        })}
                      >
                        <span
                          data-csweb="cycle-circular-text"
                          className={css({
                            color: theme.colors.contentPrimary,
                            animationIterationCount: "0",
                            animationDuration: "10000ms",
                            animationTimingFunction: "linear",
                            animationName: {
                              from: {
                                transform: "rotate(360deg)",
                              },
                              to: {
                                transform: "rotate(0deg)",
                              },
                            },
                            [theme.breakpoints.mediaQueries.small]: {
                              fontSize: "13px",
                            },
                          })}
                        >
                          <FormattedContentItem
                            scope="Services"
                            accessor={`cycle.circular.${index}`}
                          />
                        </span>
                      </div>
                      <div
                        className={css({
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          zIndex: "1",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                          overflow: "hidden",
                        })}
                      >
                        <div
                          data-csweb="cycle-circular-bg"
                          data-csweb-index={index}
                          className={css({
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            zIndex: "1",
                            borderTopLeftRadius: "50%",
                            borderTopRightRadius: "50%",
                            borderBottomRightRadius: "50%",
                            borderBottomLeftRadius: "50%",
                            clipPath:
                              index === 0
                                ? `polygon(0% 0%, 50% 0%, 50% 50%, 0% 50%)`
                                : index === 1
                                ? `polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%)`
                                : index === 2
                                ? `polygon(0% 50%, 50% 50%, 50% 100%, 0% 100%)`
                                : `polygon(50% 0%, 100% 0%, 100% 50%, 50% 50%)`,
                          })}
                        >
                          <div
                            data-csweb="cycle-circular-bg-surpress"
                            className={css({
                              position: "absolute",
                              left: "0px",
                              top: "0px",
                              right: "0px",
                              bottom: "0px",
                              borderTopLeftRadius: "50%",
                              borderTopRightRadius: "50%",
                              borderBottomRightRadius: "50%",
                              borderBottomLeftRadius: "50%",
                              background:
                                "linear-gradient(135deg, rgba(60,0,220,1) 0%, rgba(0,220,230,1) 100%)",
                            })}
                          ></div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              data-csweb="services-cycle-wrapper-2"
              className={css({
                position: "relative",
                height: "100%",
                paddingTop: theme.sizing.scale1400,
                paddingBottom: theme.sizing.scale1600,
              })}
            >
              <div
                className={css({
                  position: "absolute",
                  height: "100%",
                  width: "2px",
                  margin: "auto",
                  display: "block",
                  backgroundImage: `linear-gradient(${theme.colors.backgroundLightAccent} 50%, rgba(255,255,255,0) 0%)`,
                  backgroundPosition: "right",
                  backgroundSize: "2px 10px",
                  backgroundRepeat: "repeat-y",
                  transform: "translate(-50%, 0%)",
                  left: "50%",
                  top: "0%",
                  zIndex: "2",
                })}
              ></div>
              <VisibilitySensor
                partialVisibility
                offset={{
                  top:
                    typeof window !== "undefined" && window
                      ? window.innerHeight / 3 + 100
                      : 0,
                  bottom:
                    typeof window !== "undefined" && window
                      ? window.innerHeight / 4
                      : 0,
                }}
              >
                {({ isVisible }) => (
                  <Spring
                    to={{
                      scale: isVisible ? 1 : 0.5,
                      opacity: isVisible ? 1 : 0,
                    }}
                  >
                    {({ scale, ...style }) => (
                      <div
                        data-csweb="services-cycle-dot-2"
                        className={css({
                          position: "absolute",
                          width: "15px",
                          height: "15px",
                          background: theme.colors.primary,
                          zIndex: "3",
                          borderTopLeftRadius: "50%",
                          borderTopRightRadius: "50%",
                          borderBottomRightRadius: "50%",
                          borderBottomLeftRadius: "50%",
                          left: "50%",
                          transform: `translate(-50%, 0%) scale(${scale})`,
                          overflow: "hidden",
                          ...style,
                        })}
                      ></div>
                    )}
                  </Spring>
                )}
              </VisibilitySensor>

              <Spring
                from={{ top: -20, opacity: 0 }}
                to={{ top: 0, opacity: 1 }}
              >
                {({ top, ...style }) => (
                  <LabelLarge
                    data-csweb="services-cycle-label-2"
                    marginTop="scale800"
                    $style={{
                      fontWeight: "700",
                      zIndex: "5",
                      position: "relative",
                      transform: `translateY(${top}px)`,
                      ...style,
                    }}
                  >
                    <FormattedContentItem
                      scope="Services"
                      accessor="cycle.vertical.2"
                    />
                  </LabelLarge>
                )}
              </Spring>
            </div>
            <VisibilitySensor
              partialVisibility
              offset={{
                bottom:
                  typeof window !== "undefined" && window
                    ? window.innerHeight / 2 - 60
                    : 0,
              }}
            >
              {({ isVisible }) => [
                <Spring
                  key="cycle.vertical.3.1"
                  to={{
                    top: isVisible ? 0 : -20,
                    opacity: isVisible ? 1 : 0,
                  }}
                >
                  {({ top, ...style }) => (
                    <HeadingMedium
                      marginBottom={`-${theme.sizing.scale800}`}
                      $style={{
                        textAlign: "center",
                        transform: `translateY(${top}px)`,
                        ...style,
                      }}
                    >
                      <FormattedContentItem
                        scope="Services"
                        accessor="cycle.vertical.3"
                      />
                    </HeadingMedium>
                  )}
                </Spring>,
                <Spring
                  key="cycle.vertical.3.2"
                  from={{ scale: 0.5, color: "#ffffff" }}
                  to={{
                    scale: isVisible ? 1 : 0.01,
                  }}
                >
                  {({ scale }) => (
                    <div
                      className={css({
                        position: "relative",
                        width: "95px",
                        height: "95px",
                        transform: `translate(0%, 50%) scale(${scale})`,
                        zIndex: "20",
                      })}
                    >
                      <img
                        className={css({
                          position: "absolute",
                          width: "50px",
                          height: "50px",
                          zIndex: "3",
                          left: "50%",
                          top: "50%",
                          borderTopLeftRadius: "50%",
                          borderTopRightRadius: "50%",
                          borderBottomRightRadius: "50%",
                          borderBottomLeftRadius: "50%",
                          transform: "translate(-50%, -50%)",
                          overflow: "hidden",
                        })}
                        src={shine}
                        alt="Shine"
                      />
                      <div
                        className={css({
                          position: "absolute",
                          width: "80px",
                          height: "80px",
                          borderTopLeftRadius: "50%",
                          borderTopRightRadius: "50%",
                          borderBottomRightRadius: "50%",
                          borderBottomLeftRadius: "50%",
                          background:
                            "linear-gradient(180deg, rgba(60,0,220,1) 0%, rgba(0,220,230,1) 100%)",
                          zIndex: "2",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        })}
                      ></div>
                      <div
                        className={css({
                          position: "absolute",
                          width: "95px",
                          height: "95px",
                          zIndex: "1",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                          overflow: "hidden",
                        })}
                      >
                        <div
                          className={css({
                            position: "absolute",
                            width: "95px",
                            height: "95px",
                            zIndex: "1",
                            borderTopLeftRadius: "50%",
                            borderTopRightRadius: "50%",
                            borderBottomRightRadius: "50%",
                            borderBottomLeftRadius: "50%",
                            clipPath: `polygon(0% 0%, 50% 0%, 50% 50%, 0% 50%)`,
                            animationDuration: "1000ms",
                            animationFillMode: "both",
                            animationTimingFunction: "ease-in-out",
                            animationIterationCount: "0",
                            animationName: {
                              from: {
                                transform: "rotate(0deg)",
                              },
                              to: {
                                transform: "rotate(-360deg)",
                              },
                            },
                            "::before": {
                              content: "''",
                              position: "absolute",
                              left: "0px",
                              top: "0px",
                              right: "0px",
                              bottom: "0px",
                              borderTopLeftRadius: "50%",
                              borderTopRightRadius: "50%",
                              borderBottomRightRadius: "50%",
                              borderBottomLeftRadius: "50%",
                              background: theme.colors.backgroundSecondary,
                              animationDuration: "1000ms",
                              animationFillMode: "both",
                              animationTimingFunction: "ease-in-out",
                              animationIterationCount: "0",
                              animationName: {
                                from: {
                                  transform: "rotate(0deg)",
                                },
                                to: {
                                  transform: "rotate(360deg)",
                                },
                              },
                            },
                          })}
                        ></div>
                      </div>
                    </div>
                  )}
                </Spring>,
              ]}
            </VisibilitySensor>
          </div>
        </Cell>
      </Grid>
    </article>
  );
};

export default Cycle;
