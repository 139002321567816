import Badge from "./badge.inline.svg";
import Brain from "./brain.inline.svg";
import Cloud from "./cloud.inline.svg";
import Gears from "./gears.inline.svg";
import Mobile from "./mobile.inline.svg";
import PWA from "./pwa.inline.svg";
import Speedometer from "./speedometer.inline.svg";
import Stack from "./stack.inline.svg";

export default {
  Badge,
  Brain,
  Cloud,
  Gears,
  Mobile,
  PWA,
  Speedometer,
  Stack,
};
