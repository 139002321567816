import React from "react";

import { Layout } from "../../components/layout";
import { SEO } from "../../components/meta";
import { ContentObject } from "../../components/content";

import Heading from "./services-heading";
import Cycle from "./services-cycle";
import Attributes from "./services-attributes";
import Items from "./services-items";
import Technologies from "./services-technologies";
import Estimate from "./services-estimate";

const Services = () => (
  <>
    <SEO
      title={ContentObject.Services.meta.title}
      description={ContentObject.Services.meta.title}
    />

    <Layout header={{ position: "absolute" }} footer={{ size: "compact" }}>
      <section data-csweb="services">
        <Heading />
        <Cycle />
        <Attributes />
        <Items />
        <Technologies />
        <Estimate />
      </section>
    </Layout>
  </>
);

export default Services;
