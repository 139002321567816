import React, { createElement } from "react";
import { navigate } from "gatsby";

import { Grid, Cell } from "../../components/ui";
import { FormattedContentItem, ContentObject } from "../../components/content";

import { useStyletron } from "baseui";
import {
  LabelMedium,
  ParagraphMedium,
  HeadingMedium,
  ParagraphSmall,
} from "baseui/typography";
import { Block } from "baseui/block";
import { StyledLink } from "baseui/link";

import chevronRight from "../../assets/images/chevron-right.svg";

import Icons from "../../assets/images/services-icons";

const Items = () => {
  const [css, theme] = useStyletron();

  return (
    <article
      data-csweb="services-items"
      className={css({
        marginTop: `-${theme.sizing.scale2400}`,
        paddingBottom: theme.sizing.scale2400,
        [theme.breakpoints.mediaQueries.medium]: {
          paddingBottom: theme.sizing.scale200,
        },
      })}
    >
      <Grid>
        <Cell skip={[0, 0, 1]} span={[4, 8, 10]}>
          <Grid
            flex
            flexGridColumnCount={[1, 1, 2, 2]}
            flexGridColumnGap={["scale100", "scale1000", "scale1200"]}
            flexGridRowGap={["scale1000", "scale1000", "scale1200"]}
          >
            {ContentObject.Services.items.map((item, index) => (
              <Cell key={index} flex>
                <Block
                  onClick={() =>
                    navigate(ContentObject.Services.items[index].to)
                  }
                  overrides={{
                    Block: {
                      style: {
                        borderTopWidth: "1px",
                        borderRightWidth: "1px",
                        borderBottomWidth: "1px",
                        borderLeftWidth: "1px",
                        borderTopStyle: "solid",
                        borderRightStyle: "solid",
                        borderBottomStyle: "solid",
                        borderLeftStyle: "solid",
                        zIndex: "4",
                        borderTopColor: theme.colors.servicesCardBorder,
                        borderRightColor: theme.colors.servicesCardBorder,
                        borderBottomColor: theme.colors.servicesCardBorder,
                        borderLeftColor: theme.colors.servicesCardBorder,
                        backgroundColor: theme.colors.backgroundPrimary,
                        height: "100%",
                        padding: theme.sizing.scale1000,
                        boxSizing: "border-box",
                        position: "relative",
                        transitionProperty: "all",
                        transitionDuration: "200ms",
                        transitionTimingFunction: "ease-in-out",
                        ":hover": {
                          boxShadow: `0px 0px 50px 0px ${theme.colors.servicesCardGlow}`,
                          cursor: "pointer",
                        },
                        ":hover [data-csweb=service-learn-more]": {
                          paddingRight: "20px",
                        },
                        ":hover [data-csweb=service-learn-more]::after": {
                          width: "100%",
                        },
                        ":hover [data-csweb=service-learn-more] img": {
                          opacity: "1",
                        },
                        ":hover [data-csweb=typo-caption]": {
                          color: theme.colors.contentPrimary,
                        },
                        [theme.breakpoints.mediaQueries.small]: {
                          padding: theme.sizing.scale600,
                        },
                      },
                    },
                  }}
                >
                  <div
                    className={css({
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    })}
                  >
                    <HeadingMedium
                      color="contentAccent"
                      marginBottom="scale0"
                      marginTop="scale0"
                    >
                      {item.title}
                    </HeadingMedium>

                    <HeadingMedium
                      marginBottom="scale0"
                      marginTop="scale0"
                      color="contentSecondary"
                      $style={{ fontWeight: "700" }}
                    >
                      {item.number}
                    </HeadingMedium>
                  </div>

                  <ParagraphMedium
                    paddingTop="scale200"
                    paddingBottom="scale600"
                    $style={{
                      transitionProperty: "all",
                      transitionDuration: "200ms",
                      transitionTimingFunction: "ease-in-out",
                    }}
                  >
                    <FormattedContentItem
                      scope="Services"
                      accessor={`items.${index}.caption`}
                    />
                  </ParagraphMedium>

                  {item.attributes.map((item, index_) => (
                    <div
                      key={index_}
                      className={css({
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: theme.sizing.scale400,
                        marginBottom: theme.sizing.scale900,
                        paddingLeft: theme.sizing.scale800,
                        paddingRight: theme.sizing.scale800,
                        [theme.breakpoints.mediaQueries.small]: {
                          flexDirection: "column",
                          paddingLeft: theme.sizing.scale0,
                          paddingRight: theme.sizing.scale0,
                          textAlign: "center",
                        },
                      })}
                    >
                      <div
                        className={css({
                          height: "50px",
                          width: "50px",
                          flexShrink: "0",
                          borderTopLeftRadius: "50%",
                          borderTopRightRadius: "50%",
                          borderBottomRightRadius: "50%",
                          borderBottomLeftRadius: "50%",
                          borderTopWidth: "1px",
                          borderRightWidth: "1px",
                          borderBottomWidth: "1px",
                          borderLeftWidth: "1px",
                          borderTopStyle: "solid",
                          borderRightStyle: "solid",
                          borderBottomStyle: "solid",
                          borderLeftStyle: "solid",
                          borderTopColor: theme.colors.blue50,
                          borderRightColor: theme.colors.blue50,
                          borderBottomColor: theme.colors.blue50,
                          borderLeftColor: theme.colors.blue50,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: theme.sizing.scale800,
                          [theme.breakpoints.mediaQueries.small]: {
                            marginRight: theme.sizing.scale0,
                            marginBottom: theme.sizing.scale800,
                          },
                        })}
                      >
                        {createElement(Icons[item.icon], {
                          className: css({
                            maxHeight: "28px",
                            maxWidth: "34px",
                          }),
                        })}
                      </div>
                      <div className={css({ flexGrow: "1" })}>
                        <LabelMedium
                          color="contentAccent"
                          $style={{ fontWeight: "700", fontSize: "20px" }}
                        >
                          {item.title}
                        </LabelMedium>
                        <ParagraphSmall
                          data-csweb="typo-caption"
                          marginTop="scale100"
                          marginBottom="scale0"
                          color="contentQuaternary"
                          $style={{
                            transitionProperty: "all",
                            transitionDuration: "200ms",
                            transitionTimingFunction: "ease-in-out",
                            fontSize: "16px",
                            lineHeight: "21px",
                          }}
                        >
                          <FormattedContentItem
                            scope="Services"
                            accessor={`items.${index}.attributes.${index_}.caption`}
                          />
                        </ParagraphSmall>
                      </div>
                    </div>
                  ))}

                  <div
                    className={css({
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    })}
                  >
                    <StyledLink
                      data-csweb="service-learn-more"
                      $as="div"
                      $style={{
                        position: "relative",
                        lineHeight: "2",
                        fontWeight: "700",
                        fontSize: "15px",
                        paddingRight: "0px",
                        textDecoration: "none",
                        transitionProperty: "all",
                        transitionDuration: "400ms",
                        transitionTimingFunction: "ease-in-out",
                        color: theme.colors.contentAccent,
                        "::before": {
                          content: "''",
                          position: "absolute",
                          bottom: "0px",
                          left: "0px",
                          right: "0px",
                          height: "1px",
                          background: theme.colors.blue50,
                        },
                        "::after": {
                          content: "''",
                          position: "absolute",
                          bottom: "0px",
                          right: "0px",
                          height: "1px",
                          width: "0%",
                          background: theme.colors.contentAccent,
                          transitionProperty: "all",
                          transitionDuration: "300ms",
                          transitionTimingFunction: "ease-in-out",
                        },
                        ":hover": {
                          color: theme.colors.contentAccent,
                        },
                      }}
                      onClick={() =>
                        navigate(ContentObject.Services.items[index].to)
                      }
                    >
                      learn more
                      <img
                        alt="More"
                        src={chevronRight}
                        height="14px"
                        className={css({
                          opacity: "0",
                          position: "absolute",
                          right: "0px",
                          transitionProperty: "all",
                          transitionDuration: "200ms",
                          transitionDelay: "100ms",
                          transitionTimingFunction: "ease-in-out",
                          top: "50%",
                          transform: "translateY(-50%)",
                        })}
                      />
                    </StyledLink>
                  </div>
                </Block>
              </Cell>
            ))}
          </Grid>
        </Cell>
      </Grid>
    </article>
  );
};

export default Items;
