import React from "react";

import { Grid, Cell } from "../../components/ui";
import { FormattedContentItem, ContentObject } from "../../components/content";

import { useStyletron } from "baseui";
import { LabelLarge, ParagraphMedium } from "baseui/typography";

const Attributes = () => {
  const [css, theme] = useStyletron();

  return (
    <article
      data-csweb="services-attributes"
      className={css({
        backgroundColor: theme.colors.backgroundSecondary,
        paddingTop: theme.sizing.scale3200,
        paddingBottom: theme.sizing.scale4800,
        [theme.breakpoints.mediaQueries.medium]: {
          paddingTop: theme.sizing.scale1200,
          paddingBottom: theme.sizing.scale2400,
        },
      })}
    >
      <div
        className={css({
          marginTop: theme.sizing.scale1600,
          marginBottom: theme.sizing.scale1600,
        })}
      >
        <Grid>
          <Cell skip={[0, 0, 1]} span={[4, 8, 10]}>
            <Grid
              flex
              flexGridColumnCount={[1, 1, 3, 3]}
              flexGridColumnGap={["scale100", "scale1000", "scale2400"]}
              flexGridRowGap={["scale1000", "scale1000", "scale1200"]}
            >
              {ContentObject.Services.attributes.map((item, index) => (
                <Cell key={index} flex>
                  <LabelLarge $style={{ textAlign: "center" }}>
                    {item.title}
                  </LabelLarge>
                  <ParagraphMedium $style={{ textAlign: "center" }}>
                    <FormattedContentItem
                      scope="Services"
                      accessor={`attributes.${index}.caption`}
                    />
                  </ParagraphMedium>
                </Cell>
              ))}
            </Grid>
          </Cell>
        </Grid>
      </div>
    </article>
  );
};

export default Attributes;
